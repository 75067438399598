import styled from '@emotion/styled';
import { useController } from 'react-hook-form';

import { ErrorIndicator, ErrorMessage } from '@/components/forms/Error';
import { Label } from '@/components/forms/Label';
import {
  baseInputStyles,
  freshTextFieldInputStyles,
  StyledDescription,
} from '@/components/forms/styles';
import { canDisplayError } from '@/components/forms/utils';

import type { FormThemeProps } from '@/components/forms/HubspotForm/HubspotFormTheme';
import type { FormStyleVariant } from '@/components/forms/styles';
import type { InputHTMLAttributes } from 'react';
import type { Control, RegisterOptions } from 'react-hook-form';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  description?: string;
  variant?: FormStyleVariant;
  control: Control<any>;
  controlRules?: RegisterOptions;
  defaultValue?: string;
}

export const TextField = ({
  name,
  label,
  description,
  type = 'text',
  required,
  variant = 'original',
  disabled,
  control,
  controlRules,
  defaultValue = '',
  ...props
}: TextFieldProps) => {
  const canError = canDisplayError(variant);

  const { field, fieldState } = useController({
    control,
    name,
    rules: controlRules,
    defaultValue,
  });

  return (
    <ErrorIndicator isError={canError && fieldState.invalid}>
      <Label
        isDisabled={disabled}
        id={`${field.name}-label`}
        htmlFor={props.id ?? field.name}
        required={required}
      >
        {label}
      </Label>
      {description ? (
        <StyledDescription id={`${field.name}-description`}>
          {description}
        </StyledDescription>
      ) : null}

      <ErrorMessage
        message={fieldState?.error?.message}
        isError={canError && fieldState.invalid}
      />

      <div className="input">
        <StyledTextFieldInput
          name={field.name}
          ref={field.ref}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          type={type}
          required={required}
          variant={variant}
          aria-labelledby={`${field.name}-label`}
          aria-describedby={
            description ? `${field.name}-description` : undefined
          }
          aria-required={required}
          disabled={disabled}
          aria-invalid={fieldState.invalid}
          {...props}
          id={props.id ?? field.name}
        />
      </div>
    </ErrorIndicator>
  );
};

const StyledTextFieldInput = styled.input<{
  variant: FormStyleVariant;
  theme?: FormThemeProps;
}>`
  ${(props) => {
    switch (props.variant) {
      case 'fresh':
        return freshTextFieldInputStyles;
      case 'original':
      default:
        return baseInputStyles;
    }
  }}
`;
