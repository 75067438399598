import { ThemeProvider } from '@emotion/react';
import parse from 'html-react-parser';

import {
  Checkbox,
  FileInput,
  Label,
  RadioButton,
  Select,
  TextArea,
  TextField,
} from '@/components/forms';
import { theme } from '@/components/forms/HubspotForm/HubspotFormTheme';
import { StyledDescription } from '@/components/forms/styles';

import type { FormColourTheme } from '@/components/forms/HubspotForm/HubspotFormTheme';
import type { SelectOption } from '@/components/forms/Select/Select';
import type {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
} from 'react-hook-form';

interface HubspotFormFieldProps {
  formId: string;
  field: {
    type: string;
    name: string;
    label: string;
    description?: string;
    placeholder?: string;
    defaultValue?: string;
    required: boolean;
    hidden: boolean;
    fieldType: string;
    validation: {
      data: string;
    };
    options?: SelectOption[];
    selectedOptions?: string[];
    value?: string;
  };
  control: Control;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  colourTheme?: FormColourTheme;
}

const PHONE_NUMBER_REGEX =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const HubspotFormField = ({
  formId,
  field,
  control,
  error,
  colourTheme = 'light',
}: HubspotFormFieldProps) => {
  const id = `${field.name}-${formId}`;

  const validationOptions = () => {
    const _validationOptions: RegisterOptions = {};
    const { data } = field.validation;
    if (data) {
      // HubSpot gives us it's validation options formated as `{minLength}:{maxLength}:etc`
      const validationData = data.split(':');
      // min length
      if (validationData[0]) {
        _validationOptions.minLength = {
          value: Number(validationData[0]),
          message: `${field.label} must be longer than ${validationData[0]} characters`,
        };
      }
      // max length
      if (validationData[1]) {
        _validationOptions.maxLength = {
          value: Number(validationData[1]),
          message: `${field.label} must be shorter than ${validationData[1]} characters`,
        };
      }
    }
    if (field.fieldType === 'phonenumber') {
      _validationOptions.pattern = {
        value: PHONE_NUMBER_REGEX,
        message: `${field.label} must be a valid phone number`,
      };
    }
    if (field.required) {
      _validationOptions.required = `${field.label} is required`;
    }
    if (field.name === 'email') {
      _validationOptions.pattern = {
        value: EMAIL_REGEX,
        message: `${field.label} must be a valid email address`,
      };
    }
    return _validationOptions;
  };

  const renderInput = () => {
    switch (field.fieldType) {
      case 'text':
        return (
          <TextField
            id={id}
            name={field.name}
            label={field.label}
            type={field.name === 'email' ? 'email' : 'text'}
            inputMode={field.name === 'email' ? 'email' : 'text'}
            description={field.description}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            required={field.required}
            aria-invalid={!!error}
            control={control}
            controlRules={{ ...validationOptions() }}
            variant="fresh"
          />
        );
      case 'textarea':
        return (
          <TextArea
            id={id}
            name={field.name}
            label={field.label}
            description={field.description}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            required={field.required}
            aria-invalid={!!error}
            control={control}
            controlRules={{ ...validationOptions() }}
          />
        );
      case 'select':
        return (
          <Select
            className="input"
            id={id}
            name={field.name}
            label={field.label}
            required={field.required}
            options={field.options}
            defaultValue={field.selectedOptions[0] || ''}
            aria-invalid={!!error}
            control={control}
            controlRules={{ ...validationOptions() }}
            variant="fresh"
          />
        );
      case 'file':
        return (
          <FileInput
            id={id}
            name={field.name}
            label={field.label}
            aria-invalid={!!error}
            required={field.required}
            control={control}
            controlRules={{ ...validationOptions() }}
          />
        );
      case 'checkbox':
        return (
          <>
            <Label htmlFor={id} required={field.required}>
              {field.label}
            </Label>
            {field.description ? (
              <StyledDescription>{field.description}</StyledDescription>
            ) : null}
            <ul className="inputs-list multi-container">
              {field.options.map(({ value, label }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className="hs-form-checkbox" key={`${field.name}${index}`}>
                  <Checkbox
                    id={`${field.name}-${formId}-${index}`}
                    name={field.name}
                    label={label}
                    value={value}
                    required={field.required}
                    aria-invalid={!!error}
                    aria-describedby={
                      field.description ? `${id}-description` : undefined
                    }
                    control={control}
                    caption={field.description}
                    controlRules={{ ...validationOptions() }}
                    variant="fresh"
                  />
                </li>
              ))}
            </ul>
          </>
        );
      case 'radio':
        return (
          <>
            <Label htmlFor={id} required={field.required}>
              {field.label}
            </Label>
            <div className="input">
              <ul className="inputs-list multi-container">
                {field.options.map(({ value, label }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className="hs-form-radio" key={`${field.name}${index}`}>
                    <RadioButton
                      id={`${field.name}${index}-${formId}`}
                      name={field.name}
                      label={label}
                      value={value}
                      required={field.required}
                      aria-invalid={!!error}
                      control={control}
                      controlRules={{ ...validationOptions() }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </>
        );
      case 'booleancheckbox':
        return (
          <>
            <Checkbox
              id={id}
              name={field.name}
              value={field.value}
              label={parse(field.label)}
              required={field.required}
              defaultChecked={field.selectedOptions[0] === 'true'}
              aria-invalid={!!error}
              aria-labelledby="checkbox_example_label-label-1"
              aria-describedby="checkbox_example_label-description"
              aria-required="false"
              aria-checked="false"
              control={control}
              controlRules={{ ...validationOptions() }}
              variant="fresh"
              caption={field.description}
            />
          </>
        );
      case 'number':
        return (
          <TextField
            id={id}
            name={field.name}
            label={field.label}
            description={field.description}
            inputMode="decimal"
            required={field.required}
            aria-invalid={!!error}
            control={control}
            controlRules={{ ...validationOptions() }}
            variant="fresh"
          />
        );
      case 'phonenumber':
        return (
          <TextField
            id={id}
            name={field.name}
            label={field.label}
            description={field.description}
            type="tel"
            autoComplete="tel"
            inputMode="numeric"
            required={field.required}
            aria-invalid={!!error}
            control={control}
            controlRules={{ ...validationOptions() }}
            variant="fresh"
          />
        );
      // case 'date':
      // TODO: date field props (this field is not required at the moment)
      //   return <DateField />;
      default:
        // Fallback
        // attempt to render a standard text field for string type fields that
        // have no defined component above for their 'fieldType'
        if (field.type === 'string') {
          return (
            <TextField
              id={id}
              label={field.label}
              description={field.description}
              name={field.name}
              required={field.required}
              aria-invalid={!!error}
              control={control}
              controlRules={{ ...validationOptions() }}
              variant="fresh"
            />
          );
        }
        // eslint-disable-next-line no-console
        console.error(
          `No form component has been defined for the field type of '${field.fieldType}'`,
        );
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme[colourTheme]}>
      <div
        className={`hs-fieldtype-${field.fieldType} hs-form-field`}
        hidden={field.hidden}
      >
        {renderInput()}
      </div>
    </ThemeProvider>
  );
};
