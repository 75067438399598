import styled from '@emotion/styled';
import parse, { Element } from 'html-react-parser';

import BaseLink from '@/components/common/BaseLink/BaseLink';
import { ErrorAlert } from '@/components/common/ErrorAlert';
import { Paragraph } from '@/components/common/MarkUp';

import type { HTMLReactParserOptions, Text } from 'html-react-parser';

interface ErrorProps {
  error?: string;
  suggestedFix?: string;
}

const options: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element && domNode.name === 'a') {
      const { attribs, children } = domNode;
      return (
        <BaseLink href={attribs?.href}>{(children as Text[])[0].data}</BaseLink>
      );
    }
  },
};

export const FormError = ({ error, suggestedFix }: ErrorProps) => {
  return (
    <ErrorContainer>
      <Paragraph size="subheading-sm">
        {parse(error || 'Sorry, there was an error loading the form.', options)}
      </Paragraph>
      <Paragraph size="subheading-sm">
        {parse(
          suggestedFix || 'Please refresh the page or try again later.',
          options,
        )}
      </Paragraph>
    </ErrorContainer>
  );
};

const ErrorContainer = styled(ErrorAlert)`
  width: calc(100% - 2 * var(--spacing-medium));
  max-width: 1000px;
  margin: var(--spacing-medium) auto;
  a {
    font-weight: var(--font-weight-semibold);
  }
`;
