import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useController } from 'react-hook-form';

import { screen } from '@/components/common/breakpoints';
import { Paragraph } from '@/components/common/MarkUp';
import { ErrorIndicator, ErrorMessage } from '@/components/forms/Error';
import { baseInputStyles, sharedInputStyles } from '@/components/forms/styles';
import { canDisplayError } from '@/components/forms/utils';
import { bodyLarge } from '@/styles/typography';

import type {
  FormStyleVariant,
  InputCSSProps,
} from '@/components/forms/styles';
import type { InputHTMLAttributes, ReactNode } from 'react';
import type { Control, RegisterOptions } from 'react-hook-form';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  caption?: string;
  control: Control<any>;
  controlRules?: RegisterOptions;
  variant?: FormStyleVariant;
  disabled?: boolean;
}

export const Checkbox = ({
  className,
  name,
  label,
  caption,
  required,
  control,
  controlRules,
  variant = 'original',
  disabled,
  ...props
}: CheckboxProps) => {
  const canError = canDisplayError(variant);

  const { field, fieldState } = useController({
    control,
    name,
    rules: controlRules,
  });

  return (
    <ErrorIndicator isError={canError && fieldState.invalid}>
      <ErrorMessage
        message={fieldState?.error?.message}
        isError={canError && fieldState.invalid}
      />
      <StyledCheckboxContainer className={className} variant={variant}>
        <StyledCheckboxInput
          name={field.name}
          ref={field.ref}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          type="checkbox"
          required={required}
          variant={variant}
          aria-labelledby={`${field.name}-label`}
          aria-required={required}
          disabled={disabled}
          {...props}
          id={props.id ?? field.name}
        />

        <StyledCheckboxLabel
          variant={variant}
          disabled={disabled}
          id={`${field.name}-label`}
          htmlFor={props.id ?? field.name}
        >
          {label}
        </StyledCheckboxLabel>
      </StyledCheckboxContainer>
      {variant === 'fresh' && caption && (
        <CaptionContainer disabled={disabled}>{caption}</CaptionContainer>
      )}
    </ErrorIndicator>
  );
};

const StyledCheckboxContainer = styled.div<{ variant: FormStyleVariant }>`
  display: flex;
  align-items: ${(p) => (p.variant === 'fresh' ? 'flex-start' : 'center')};
  padding: ${(p) =>
    p.variant === 'fresh' ? 'var(--spacing-xx-small) 0' : '0'};
`;

const originalTextStyles = css`
  margin: var(--spacing-300) 0;

  ${screen.lg} {
    padding-left: 0;
  }
`;

const freshTextStyles = ({ theme }) => css`
  ${bodyLarge}
  color: var(${theme.textColour});
  a {
    color: var(${theme.actionColour});
  }
`;

const StyledCheckboxLabel = styled.label<InputCSSProps>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.24;
    `}

  ${(props) => {
    switch (props.variant) {
      case 'fresh':
        return freshTextStyles;
      case 'original':
      default:
        return originalTextStyles;
    }
  }}
`;

const freshCheckboxStyles = ({ theme }) => css`
  ${sharedInputStyles}
  flex-shrink: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: var(--spacing-xx-small);
  border-radius: 6px;
  border: solid 2px var(--colour-border-strong);
  border-color: var(${theme.checkboxBorderColour});
  color: var(${theme.inputColour});
  background-color: var(${theme.inputBackground});
  position: relative;

  :focus {
    outline-offset: 1px;
    border: 2px solid var(--colour-border-strong);
    outline: 2px solid var(${theme.actionColour});
  }

  ::before {
    display: block;
    content: '';
  }

  &:checked {
    background: var(${theme.actionColour});
    border: 2px solid var(${theme.actionColour});
    &::before {
      content: url(/images/${theme.checkboxIcon});
      position: absolute;
      transform: scale(0.8);
      left: -2px;
      top: -2px;
    }
  }
`;

const StyledCheckboxInput = styled.input<InputCSSProps>`
  ${(props) => {
    switch (props.variant) {
      case 'fresh':
        return freshCheckboxStyles;
      case 'original':
      default:
        return baseInputStyles;
    }
  }}
`;

const CaptionContainer = styled(Paragraph)<InputCSSProps>`
  color: ${({ theme }) => theme.inputColour && `var(${theme.inputColour})`};
  ${bodyLarge}
  line-height: 1.285;
  margin-top: var(--spacing-xxx-small);
  margin-left: var(--spacing-400);

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.24;
    `}
`;
