import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { Heading, HeadingLevel, Paragraph } from '@/components/common/MarkUp';

import type { FormColourTheme } from '@/components/forms/HubspotForm/HubspotFormTheme';

export const ThankYou = ({
  colourTheme = 'light',
}: {
  colourTheme?: FormColourTheme;
}) => {
  return (
    <Container colourTheme={colourTheme} className="thank-you-container">
      <HeadingLevel>
        <Heading size="heading-sm">Thanks for getting in touch</Heading>
        <Paragraph size="subheading-sm">
          Our team will get back to you as soon as possible
        </Paragraph>
        <StyledButton
          href="/"
          aria-label="Go to homepage"
          variant={colourTheme === 'light' ? 'solid-light' : 'solid-dark'}
        >
          Go to homepage
        </StyledButton>
      </HeadingLevel>
    </Container>
  );
};

const Container = styled.div<{
  colourTheme: FormColourTheme;
}>`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  height: 100%;
  width: 100%;
  color: var(--decorative-dark-ultraviolet);
  align-items: center;
  justify-content: center;
  padding: var(--spacing-1000) var(--spacing-small);
  h2,
  p {
    ${(p) => p.colourTheme === 'dark' && 'color: var(--text-inverse-strong)'}
  }

  ${Paragraph}, ${Heading} {
    ${screen.md} {
      width: 65%;
    }
    text-align: center;
  }

  ${Paragraph} {
    margin-top: var(--spacing-xx-small);
    margin-bottom: var(--spacing-small);
  }
`;

const StyledButton = styled(Button)`
  margin-top: var(--spacing-500);
`;
