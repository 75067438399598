import styled from '@emotion/styled';
import { useController } from 'react-hook-form';

import { baseInputStyles, radioText } from '@/components/forms/styles';
import { bodyLarge } from '@/styles/typography';

import type { InputHTMLAttributes } from 'react';
import type { Control, RegisterOptions } from 'react-hook-form';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  control?: Control;
  controlRules?: RegisterOptions;
}

export const RadioButton = ({
  id,
  className,
  name,
  label,
  required,
  control,
  controlRules,
  ...props
}: RadioButtonProps) => {
  const { field } = useController({
    control,
    name,
    rules: controlRules,
  });

  return (
    <StyledRadioButtonLabelWrapper className={className} htmlFor={id}>
      <StyledRadioButtonInput
        id={id}
        name={field.name}
        ref={field.ref}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        type="radio"
        required={required}
        {...props}
      />
      <span>{label}</span>
    </StyledRadioButtonLabelWrapper>
  );
};

const StyledRadioButtonLabelWrapper = styled.label`
  display: flex;
  align-items: center;

  span {
    ${radioText}
    ${bodyLarge}
  }
`;

const StyledRadioButtonInput = styled.input`
  ${baseInputStyles}
`;
