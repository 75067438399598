import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { sendGTMEvent } from '@next/third-parties/google';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { ThankYou } from '@/components/common/CtaFormThankYou';
import { FormError } from '@/components/forms/HubspotForm/FormError';
import { HubspotConsent } from '@/components/forms/HubspotForm/HubspotConsent';
import { HubspotFormField } from '@/components/forms/HubspotForm/HubspotFormField';
import { getLegalConsentFromMetadata } from '@/components/forms/HubspotForm/hubspotUtils';
import { ProgressIndicator } from '@/components/forms/ProgressIndicator';
import { getCookie } from '@/lib/utils';
import { bodyLarge } from '@/styles/typography';

import type { FormColourTheme } from '@/components/forms/HubspotForm/HubspotFormTheme';
import type { MetaData } from '@/components/forms/HubspotForm/hubspotUtils';
import type { FormStyleVariant } from '@/components/forms/styles';

type formType = 'contactPage' | 'emailSignUp' | 'hubspotBlock';
export interface HubspotFormProps {
  formData: {
    guid: string;
    submitText?: string;
    metaData: MetaData;
    formFieldGroups: {
      fields: {
        name: string;
        label: string;
        type: string;
        required: boolean;
        placeholder: string;
        hidden: boolean;
        richText?: {
          content: string;
        };
      }[];
      richText?: {
        content: string;
      };
    }[];
  };
  submitText?: string;
  onSubmit?: () => void;
  endpoint?: string;
  variant?: FormStyleVariant;
  colourTheme?: FormColourTheme;
  breakpoints?: number[];
  formType?: formType;
}

export const HubspotForm = ({
  formData,
  onSubmit,
  endpoint,
  colourTheme = 'light',
  breakpoints,
  formType,
}: HubspotFormProps) => {
  const router = useRouter();
  const methods = useForm();
  const { submitText = 'Submit', formFieldGroups, guid, metaData } = formData;

  const stepBreaks = breakpoints?.filter(
    (point) => point < formFieldGroups.length,
  );
  const isSingleStep = !breakpoints || stepBreaks.length === 0;

  const id = `${formData.guid}-${stepBreaks?.join('-') || 0}`;

  const [hasHubspotError, setHasHubspotError] = useState(false);
  const [utm, setUTM] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const isFinalStep = isSingleStep || currentStep === stepBreaks.length + 1;

  const legalConsent = getLegalConsentFromMetadata(metaData);

  const getStepData = () => {
    if (isSingleStep) {
      return { 1: formFieldGroups };
    } else {
      const stepData = {};
      let currentIndex = 0;
      stepBreaks.forEach((breakpoint, index) => {
        const fields = formFieldGroups.slice(currentIndex, breakpoint);
        stepData[index + 1] = fields;
        currentIndex = breakpoint;
      });
      if (currentIndex < formFieldGroups.length) {
        stepData[stepBreaks.length + 1] = formFieldGroups.slice(currentIndex);
      }
      return stepData;
    }
  };

  const stepData = getStepData();

  const handleNextButton = async (fields, e) => {
    let fieldsToValidate = [];
    fields.map((fieldGroup) =>
      fieldGroup?.fields?.map((field) => {
        fieldsToValidate = [...fieldsToValidate, field.name];
      }),
    );

    await methods.trigger(fieldsToValidate);

    if (
      Object.keys(methods.formState.errors).length &&
      Object.keys(methods.formState.errors).filter((i) =>
        fieldsToValidate.includes(i),
      ).length > 0
    ) {
      return;
    } else {
      e.preventDefault();
      if (currentStep === stepBreaks.length) {
        setCurrentStep((currentStep) => currentStep + 1);
      } else setCurrentStep((currentStep) => currentStep + 1);
    }
  };

  const handleBackButton = () => {
    if (currentStep === stepBreaks.length + 1) {
      setCurrentStep((currentStep) => currentStep - 1);
    } else {
      setCurrentStep((currentStep) => currentStep - 1);
    }
  };

  const handleOnSubmit = async (data, event) => {
    event.preventDefault();
    setHasHubspotError(false);
    let fields = Object.keys(data).map((key) => ({
      name: key,
      value: data[key],
    }));
    if (utm) fields = [...fields, ...utm];
    const response = await fetch(endpoint ?? `/api/form/${guid}`, {
      body: JSON.stringify({
        fields,
        formId: guid,
        context: {
          pageUri: window.location.href,
          pageName: document.title,
        },
        legalConsent: legalConsent
          ? {
              legitimateInterest: {
                value: true,
                subscriptionTypeId:
                  legalConsent.legitimateInterestSubscriptionTypes[0],
                // Legal basis on the Hubspot side is one of 3 options, whereas the form API only accepts 2 different values.
                // My guess is that a "Lead" is a better default than a "Customer" here.
                legalBasis:
                  legalConsent.legitimateInterestLegalBasis ===
                  'LEGITIMATE_INTEREST_CLIENT'
                    ? 'CUSTOMER'
                    : 'LEAD',
                text: legalConsent.privacyPolicyText,
              },
            }
          : null,
      }),
      method: 'POST',
    });
    const result = await response.json();

    if (result.error || result.status === 'error' || result.errors?.length) {
      setHasHubspotError(true);
      return;
    }
    window?.heap?.track('Hubspot-form-success');

    sendGTMEvent({
      event: 'hubspot-form-success',
      'hs-form-guid': guid,
    });

    if (onSubmit) {
      onSubmit();
    }
  };

  const handleOnError = (_, event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (
      router.isReady &&
      Object.keys(router.query).filter((key) => /utm/.test(key)).length > 0
    ) {
      const fields = Object.keys(router.query).map((key) => ({
        name: key,
        value: router.query[key],
      }));
      setUTM(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    const utmCookieString = getCookie('utmParams');
    if (utmCookieString) {
      const utmElsArray = utmCookieString
        .split('&')
        .map((utmEl) => utmEl.split('='));
      utmElsArray.forEach(([utmKey, utmVal]) =>
        methods.setValue(utmKey, utmVal),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasHubspotError && methods.formState.isSubmitSuccessful) {
    return <ThankYou colourTheme={colourTheme} />;
  }

  return (
    <FormProvider {...methods}>
      <FormWrapper
        method="post"
        action={`/api/form/submit/${guid}`}
        onSubmit={methods.handleSubmit(handleOnSubmit, handleOnError)}
        id={`form-${id}`}
        noValidate
      >
        {hasHubspotError ? (
          <FormError
            error="There was an error submitting the form."
            suggestedFix="Please try again or email us at <a href='mailto:info@multiverse.io'>info@multiverse.io</a>"
          />
        ) : null}
        {!isSingleStep && (
          <ProgressIndicator
            numberOfPages={stepBreaks?.length + 1}
            activePage={currentStep}
          />
        )}
        <FormFieldsStack>
          {stepData[currentStep]?.map((fieldGroup, index) => {
            if (fieldGroup.richText?.content && !fieldGroup.fields?.length) {
              return (
                <RichTextField as={'div'} key={index}>
                  {parse(fieldGroup.richText.content)}
                </RichTextField>
              );
            }
            return fieldGroup?.fields?.map((field, index) => {
              return (
                <fieldset
                  style={{ display: field.hidden ? 'none' : 'block' }}
                  key={`fieldGroup-${index}`}
                >
                  <HubspotFormField
                    formId={guid}
                    key={field.name}
                    field={field}
                    control={methods.control}
                    error={methods.formState.errors[field.name]}
                    colourTheme={colourTheme}
                  />

                  {fieldGroup.richText?.content && (
                    <RichTextField>
                      {parse(fieldGroup.richText.content)}
                    </RichTextField>
                  )}
                </fieldset>
              );
            });
          })}
        </FormFieldsStack>
        {legalConsent && (
          <HubspotConsent consentData={legalConsent} theme={colourTheme} />
        )}
      </FormWrapper>
      <ButtonsWrapper
        formType={formType}
        className={formType === 'contactPage' ? 'footer-container' : ''}
      >
        {!isSingleStep && currentStep > 1 && (
          <Button
            variant="flat-light"
            onClick={handleBackButton}
            type="button"
            className="back-button"
            icon="ArrowLeft"
            iconPosition="left"
          >
            Back
          </Button>
        )}
        {!isFinalStep ? (
          <Button
            variant="solid-light"
            onClick={(e) => handleNextButton(stepData[currentStep], e)}
            form={`form-${id}`}
            icon="ArrowRight"
          >
            Next
          </Button>
        ) : (
          <Button
            variant={colourTheme === 'light' ? 'solid-light' : 'solid-dark'}
            className={`hs-button ${methods.formState.isSubmitting ? 'disabled' : ''} `}
            form={`form-${id}`}
            type="submit"
            icon="Email"
          >
            {methods.formState.isSubmitting ? 'Submitting...' : submitText}
          </Button>
        )}
      </ButtonsWrapper>
    </FormProvider>
  );
};

const FormWrapper = styled.form<{ variant?: FormStyleVariant }>`
  display: flex;
  gap: var(--spacing-small);
  flex-direction: column;
  justify-content: space-between;
`;

const FormFieldsStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`;

const RichTextField = styled.p`
  ${bodyLarge}
  margin-top: var(--spacing-xxx-small);
  color: var(--text-warm-subtle);
`;

const hubspotBlockmStyle = css`
  justify-content: start;
  padding: 0;
  padding-top: var(--spacing-medium);
  button {
    max-width: 300px;
    width: 100%;
  }
`;

const emailSignUpStyle = css`
  padding: 0;
  button {
    width: 100%;
  }

  ${screen.lg} {
    button {
      max-width: 300px;
    }
  }
`;

const fullPageFormStyle = css`
  justify-content: center;

  button {
    width: 100%;
    max-width: 400px;
  }

  ${screen.md} {
    position: sticky;
    bottom: 0;
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.7);
    border-radius: 0 0 var(--radius-200) var(--radius-200);
    display: flex;
    justify-content: flex-end;

    button {
      width: 50%;
    }
  }
`;

const ButtonsWrapper = styled.div<{ formType?: formType }>`
  padding: var(--spacing-medium) var(--spacing-300);
  width: 100%;
  display: flex;
  justify-content: end;
  button {
    justify-content: center;
    width: 50%;
    &.back-button {
      margin-right: var(--spacing-xx-small);
      width: 48%;
    }

    span {
      width: 100%;
    }
  }

  ${({ formType }) => {
    switch (formType) {
      case 'contactPage':
        return fullPageFormStyle;
      case 'hubspotBlock':
        return hubspotBlockmStyle;
      case 'emailSignUp':
        return emailSignUpStyle;
      default:
        return;
    }
  }};
`;
