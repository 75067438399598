import { Fragment } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colourTokens } from '@/styles/colours';

interface ProgressIndicatorProps {
  numberOfPages: number;
  activePage: number;
}

enum PageState {
  InProgress = 'in-progress',
  Done = 'done',
}

const ProgressIndicator = ({
  numberOfPages,
  activePage,
}: ProgressIndicatorProps) => {
  return (
    <ProgressIndicatorContainer>
      {Array.from({ length: numberOfPages }).map((_, index) => {
        return (
          <Fragment key={`stage-${index + 1}`}>
            {index > 0 && <Line />}
            <Circle
              pageState={
                // eslint-disable-next-line no-nested-ternary
                index + 1 === activePage
                  ? PageState.InProgress
                  : index + 1 < activePage
                    ? PageState.Done
                    : null
              }
            />
          </Fragment>
        );
      })}
    </ProgressIndicatorContainer>
  );
};

const ProgressIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Line = styled.div`
  width: 18px;
  height: 1px;
  margin: auto 3px;
  background-color: var(${colourTokens.surface.coolStrong.token});
`;

const inProgressCircleStyles = css`
  width: 16px;
  height: 16px;
  background-color: var(${colourTokens.surface.subtle.token});
  border: 2px solid var(${colourTokens.border.action.token});
  margin: auto 0px;
  animation: activate 0.6s 1 ease-out;
`;

const doneCircleStyles = css`
  background-color: var(${colourTokens.surface.primary.token});
  animation: complete 0.6s 1 ease-out;
`;

const Circle = styled.div<{ pageState?: PageState }>`
  border-radius: 50%;
  margin: auto 2px;
  width: 12px;
  height: 12px;
  background-color: var(${colourTokens.surface.coolStrong.token});
  border: none;

  ${(p) => p.pageState === 'in-progress' && inProgressCircleStyles}
  ${(p) => p.pageState === 'done' && doneCircleStyles}

@keyframes activate {
    from {
      transform: scale(0.8);
      opacity: 0.3;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes complete {
    from {
      transform: scale(0.6);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export { ProgressIndicator };
