export type FormColourTheme = 'light' | 'dark';

export interface FormThemeProps {
  inputColour: string;
  inputBackground: string;
  textColour: string;
  borderColour: string;
  errorColour: string;
  containerColour: string;
  checkboxBorderColour: string;
  checkboxIcon: string;
  actionColour: string;
}

export const theme = {
  light: {
    inputColour: '--text-strong',
    inputBackground: '--background-subtle',
    textColour: '--text-warm-subtle',
    borderColour: '--border-cool-base',
    errorColour: '--colour-error-rgb',
    containerColour: '--surface-subtle',
    checkboxBorderColour: '--colour-border-strong',
    actionColour: '--decorative-ultraviolet',
    checkboxIcon: 'Check-light.svg',
    radioBackground: '--surface-subtle',
    radioBackgroundChecked: '--background-primary',
    radioBorderColourChecked: '--background-primary',
    radioHoverColour: '--border-cool-subtle',
    radioFocusColour: '--decorative-dark-ultraviolet',
    radioText: '--text-cool-base',
  },
  dark: {
    inputColour: '--colour-inverse-base',
    inputBackground: '--colour-inverse-subtle',
    textColour: '--text-inverse-strong',
    borderColour: '--border-cool-subtle',
    errorColour: '--colour-text-inverse-error-rgb',
    containerColour: '--colour-surface-inverse-strong',
    checkboxBorderColour: '--colour-border-inverse-strong',
    actionColour: '--colour-inverse-action',
    checkboxIcon: 'Check-dark.svg',
    radioBackground: '--surface-inverse-subtle',
    radioBackgroundChecked: '--decorative-blue',
    radioBorderColourChecked: '--decorative-blue',
    radioHoverColour: '--icon-warm-subtle',
    radioFocusColour: '--decorative-dark-eclipse',
    radioText: '--text-inverse-cool-base',
  },
};
