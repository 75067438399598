import styled from '@emotion/styled';
import { useController } from 'react-hook-form';

import { Label } from '@/components/forms/Label';
import { baseInputStyles, StyledDescription } from '@/components/forms/styles';

import type { TextareaHTMLAttributes } from 'react';
import type { Control, RegisterOptions } from 'react-hook-form';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  description: string;
  control: Control;
  controlRules?: RegisterOptions;
}

export const TextArea = ({
  id,
  name,
  label,
  description,
  required,
  control,
  controlRules,
  ...props
}: TextAreaProps) => {
  const { field } = useController({
    control,
    name,
    rules: controlRules,
  });

  return (
    <>
      <Label id={`${id}-label`} htmlFor={id} required={required}>
        {label}
      </Label>
      {description ? (
        <StyledDescription id={`${id}-description`}>
          {description}
        </StyledDescription>
      ) : null}
      <StyledTextArea
        id={id}
        name={field.name}
        ref={field.ref}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        required={required}
        aria-labelledby={`${id}-label`}
        aria-describedby={description ? `${id}-description` : undefined}
        aria-required={required}
        {...props}
      />
    </>
  );
};

const StyledTextArea = styled.textarea`
  ${baseInputStyles}

  resize: vertical;
  height: 125px;
`;
