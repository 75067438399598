import styled from '@emotion/styled';
import { m } from 'framer-motion';

import { Icon } from '@/components/common/Icon';
import { Paragraph } from '@/components/common/MarkUp';
import {
  errorEnterTransition,
  errorExitTransition,
} from '@/components/forms/utils';
import { bodyMedium, labelLarge } from '@/styles/typography';

import type { FormThemeProps } from '@/components/forms/HubspotForm/HubspotFormTheme';
import type { Variants } from 'framer-motion';

interface ErrorMessageProps {
  message: string;
  isError: boolean;
}

const variants: Variants = {
  default: {
    opacity: 0,
    height: 0,
    marginBlock: 0,
    transition: errorExitTransition,
    visibility: 'hidden',
  },
  error: {
    opacity: 1,
    height: 'auto',
    marginBlock: 'var(--spacing-xx-small)',
    transition: errorEnterTransition,
    visibility: 'visible',
  },
};

export const ErrorMessage = ({ message, isError }: ErrorMessageProps) => {
  const htmlToTextMessage = message?.replace(/<[^>]+>/g, '');
  return (
    <MessageContainer
      initial="default"
      animate={isError ? 'error' : 'default'}
      variants={variants}
      role="alert"
      hidden={!isError}
    >
      <Icon icon="AlertCircle" />
      <ErrorText size="body-lg">{htmlToTextMessage}</ErrorText>
    </MessageContainer>
  );
};

const MessageContainer = styled(m.div)<{
  theme?: FormThemeProps;
}>`
  color: ${(props) =>
    `rgb(var(${props.theme.errorColour ?? '--colour-error-rgb'}))`};
  ${labelLarge}
  letter-spacing: 0.05px; // Magic number from the Figma designs
  overflow: hidden;

  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;
  gap: var(--spacing-xxx-small);
`;

const ErrorText = styled(Paragraph)`
  ${bodyMedium}
`;
