import styled from '@emotion/styled';
import parse from 'html-react-parser';

import { bodyMedium } from '@/styles/typography';

import type { ConsentData } from './hubspotUtils';

interface HubspotConsentProps {
  consentData: ConsentData;
  theme?: 'dark' | 'light';
}

export const HubspotConsent = ({
  consentData,
  theme = 'dark',
}: HubspotConsentProps) => {
  switch (consentData.processingConsentType) {
    case 'IMPLICIT':
      // Implicit covers the "legitimate Interest" mode, as well as the "form submit as consent" mode.
      // These two modes are differentiated by the isLegitimateInterest boolean
      // TODO: Add "form submit as consent" mode
      return (
        <ConsentText componentTheme={theme}>
          {parse(consentData.privacyPolicyText)}
        </ConsentText>
      );

    case 'REQUIRED_CHECKBOX':
      // Required checkbox covers the mode that requires a checkbox to be ticked
      // TODO: Add this mode
      return null;

    default:
      return null;
  }
};

const ConsentText = styled.div<{
  componentTheme: 'light' | 'dark';
}>`
  ${bodyMedium}
  color: ${({ componentTheme }) =>
    componentTheme === 'light'
      ? 'var(--text-warm-subtle)'
      : 'var(--text-inverse-strong)'};

  a {
    color: ${({ componentTheme }) =>
      componentTheme === 'light'
        ? 'var(--decorative-ultraviolet)'
        : 'var(--colour-inverse-action)'};
  }
`;
