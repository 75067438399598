import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { m } from 'framer-motion';

import {
  errorEnterTransition,
  errorExitTransition,
} from '@/components/forms/utils';

import type { FormTheme } from '@emotion/react';
import type { Variants } from 'framer-motion';
import type { ReactNode } from 'react';

interface ErrorIndicatorProps {
  children: ReactNode;
  isError: boolean;
}

const variants: Variants = {
  default: {
    borderLeftColor: 'rgba(var(--colour-error-rgb), 0)',
    paddingLeft: 0,
    transition: errorExitTransition,
  },
  error: (errorColour) => ({
    borderLeftColor: `rgba(var(${errorColour}), 1)`,
    paddingLeft: 'var(--spacing-x-small)',
    transition: errorEnterTransition,
  }),
};

export const ErrorIndicator = ({ children, isError }: ErrorIndicatorProps) => {
  const { errorColour }: FormTheme = useTheme() || null;

  return (
    <Indicator
      initial="default"
      custom={errorColour ?? '--colour-error-rgb'}
      animate={isError ? 'error' : 'default'}
      variants={variants}
    >
      {children}
    </Indicator>
  );
};

const Indicator = styled(m.div)`
  border-left: solid 2px rgba(var(--colour-error-rgb), 0);

  * + & {
    margin-top: var(--spacing-small);
  }
`;
