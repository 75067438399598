import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useController } from 'react-hook-form';

import { ErrorIndicator, ErrorMessage } from '@/components/forms/Error';
import { Label } from '@/components/forms/Label';
import { baseInputStyles, freshInputStyles } from '@/components/forms/styles';
import { canDisplayError } from '@/components/forms/utils';

import type {
  FormStyleVariant,
  InputCSSProps,
} from '@/components/forms/styles';
import type { Theme } from '@emotion/react';
import type { SelectHTMLAttributes } from 'react';
import type {
  Control,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

export type SelectOption = {
  value: string;
  label: string;
  hidden: boolean;
};

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: SelectOption[];
  register?: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  variant?: FormStyleVariant;
  control: Control<any>;
  controlRules?: RegisterOptions;
}

export const Select = ({
  className,
  name,
  label,
  required,
  options,
  defaultValue,
  variant = 'original',
  disabled,
  control,
  controlRules,
  ...props
}: SelectProps) => {
  const canError = canDisplayError(variant);

  const { field, fieldState } = useController({
    control,
    name,
    rules: controlRules,
  });

  return (
    <ErrorIndicator isError={canError && fieldState.invalid}>
      <Label
        id={`${field.name}-label`}
        htmlFor={props.id ?? field.name}
        required={required}
        isDisabled={disabled}
      >
        {label}
      </Label>

      <ErrorMessage
        message={fieldState?.error?.message}
        isError={canError && fieldState.invalid}
      />

      <SelectInputWrapper
        className={className}
        variant={variant}
        disabled={disabled}
      >
        <StyledSelect
          name={field.name}
          ref={field.ref}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          required={required}
          defaultValue={defaultValue}
          variant={variant}
          disabled={disabled}
          aria-invalid={fieldState.invalid}
          aria-labelledby={`${field.name}-label`}
          {...props}
          id={props.id ?? field.name}
        >
          <option key="default" disabled value="">
            Please Select
          </option>
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              hidden={option.hidden}
            >
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </SelectInputWrapper>
    </ErrorIndicator>
  );
};

const SelectInputWrapper = styled.div<InputCSSProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &::after {
    content: ' ';
    border-top: 3px solid var(--decorative-dark-ultraviolet);
    border-left: 3px solid var(--decorative-dark-ultraviolet);
    border-radius: 1px;
    position: absolute;
    height: 15px;
    width: 15px;
    align-self: flex-end;
    margin-right: var(--spacing-300);
    transform: translate(0px, -2px) rotateZ(-135deg);
    pointer-events: none;

    ${(props) =>
      props.disabled &&
      css`
        opacity: 0.24;
      `}

    ${(props) =>
      props.variant === 'fresh' &&
      css`
        height: 10px;
        width: 10px;
        border-top: 3px solid var(--colour-inverse-subtle-dark-text);
        border-left: 3px solid var(--colour-inverse-subtle-dark-text);
      `}
  }
`;

const originalSelectStyles = (theme: Theme) => css`
  ${baseInputStyles({ theme })}

  &.is-placeholder {
    color: var(--colour-grey);
  }

  option:not(:first-of-type) {
    color: var(--decorative-dark-ultraviolet);
  }
`;

const StyledSelect = styled.select<InputCSSProps>`
  appearance: none;
  -webkit-appearance: none;

  ${(props) => {
    switch (props.variant) {
      case 'fresh':
        return freshInputStyles;

      case 'original':
      default:
        return originalSelectStyles;
    }
  }}
`;
