/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from '@emotion/styled';

import { Label } from '@/components/forms/Label';
import { baseInputStyles } from '@/components/forms/styles';

// TODO: Date field is not required at the moment
// this component will need finishing
export const DateField = () => {
  return (
    <div className="hs-form__field hs-form__field-date_dummy hs-date_dummy">
      <Label
        id="date_dummy-label"
        htmlFor="date_dummy-input"
        className="hs-form__field__label"
        data-required="false"
      >
        <span>Date</span>
      </Label>
      <div>
        <StyledDateFieldInput
          id="date_dummy-fragment-0-input"
          name="date_dummy__MM"
          placeholder="MM"
          aria-invalid="false"
          aria-labelledby="date_dummy-label"
          aria-describedby="date_dummy-description"
          aria-required="false"
          maxLength={2}
          className="hs-form__field__input date-input--fragment date-input--mm"
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          defaultValue=""
        />
        <StyledDateFieldInput
          id="date_dummy-fragment-2-input"
          name="date_dummy__DD"
          placeholder="DD"
          aria-invalid="false"
          aria-labelledby="date_dummy-label"
          aria-describedby="date_dummy-description"
          aria-required="false"
          maxLength={2}
          className="hs-form__field__input date-input--fragment date-input--dd"
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          defaultValue=""
        />
        <StyledDateFieldInput
          id="date_dummy-fragment-4-input"
          name="date_dummy__YYYY"
          placeholder="YYYY"
          aria-invalid="false"
          aria-labelledby="date_dummy-label"
          aria-describedby="date_dummy-description"
          aria-required="false"
          maxLength={4}
          className="hs-form__field__input date-input--fragment date-input--yyyy"
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          defaultValue=""
        />
      </div>
    </div>
  );
};

const StyledDateFieldInput = styled.input`
  ${baseInputStyles}

  &.date-input--dd, &.date-input--mm {
    width: 90px;
  }

  &.date-input--yyyy {
    width: 120px;
  }
`;
