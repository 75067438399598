import styled from '@emotion/styled';

import { colourTokens } from '@/styles/colours';

export const Loader = () => {
  return (
    <LoaderWrapper>
      <Loading />
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto 2px;
  border: 8px solid var(${colourTokens.surface.primary.token});
  margin: auto 0px;
  animation: loader 1.5s infinite alternate;

  @media (prefers-reduced-motion) {
    animation: loader 6s infinite alternate;
  }

  @keyframes loader {
    from {
      transform: scale(0.1);
      opacity: 1;
    }
    to {
      transform: scale(1);
      opacity: 0.2;
    }
  }
`;
