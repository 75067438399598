import styled from '@emotion/styled';

import { bodyLarge } from '@/styles/typography';

import type { FormThemeProps } from '@/components/forms/HubspotForm/HubspotFormTheme';
import type { LabelHTMLAttributes } from 'react';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean;
  isDisabled?: boolean;
}

export const Label = ({
  htmlFor,
  children,
  required,
  isDisabled = false,
  ...props
}: LabelProps) => {
  return (
    <StyledLabel isDisabled={isDisabled} htmlFor={htmlFor} {...props}>
      {children}
      {required ? <span>*</span> : null}
    </StyledLabel>
  );
};

const StyledLabel = styled.label<{
  isDisabled: boolean;
  theme?: FormThemeProps;
}>`
  display: block;

  opacity: ${(props) => (props.isDisabled ? '0.24' : '1')};

  ${bodyLarge}
  color: ${({ theme }) => `var(${theme.textColour})`};
  margin: auto 0 var(--spacing-xx-small) 0;
`;
