import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { bodyLarge } from '@/styles/typography';

import type { FormThemeProps } from '@/components/forms/HubspotForm/HubspotFormTheme';

export type FormStyleVariant = 'original' | 'fresh';

export interface InputCSSProps {
  variant?: FormStyleVariant;
  disabled?: boolean;
  theme?: FormThemeProps;
}

export const StyledDescription = styled.div`
  margin-bottom: var(--spacing-xx-small);
`;

export const sharedInputStyles = css`
  background-color: var(--background-subtle);
  outline: none;
  width: 100%;

  &:disabled {
    opacity: 0.24;
  }

  &[type='checkbox'],
  &[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
  }
`;

export const baseInputStyles = ({ theme }) => css`
  ${sharedInputStyles}
  padding: var(--spacing-xx-small) var(--spacing-500);

  border: var(${theme.borderColour}) 2px solid;
  border-radius: var(--radius-50);
  color: var(--decorative-dark-ultraviolet);
  height: 56px;

  ${screen.md} {
    padding: var(--spacing-xx-small) var(--spacing-300);
    ${bodyLarge}
  }

  &.invalid {
    border: var(--decorative-red) 2px solid;
  }

  &:disabled {
    opacity: 0.24;
  }

  &[type='checkbox'],
  &[type='radio'] {
    width: 22px;
    height: 22px;
    margin-right: var(--spacing-300);
    border-radius: 0;
    padding: 0;
    vertical-align: center;
    flex-shrink: 0;

    &[type='checkbox'] {
      ::before {
        display: block;
        content: '';
        padding-left: 2px;
        margin-top: -4px;
      }

      &:checked::before {
        content: url('/images/tick-checkbox.svg');
        margin-left: -2px;
      }
    }

    &[type='radio'] {
      border-radius: 50%;
      transition: box-shadow 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(${theme.radioBackground});

      ::before {
        content: '';
        display: block;
        width: 38%;
        height: 38%;
        border-radius: 50%;
      }

      &:not(:checked):hover {
        box-shadow: 0 0 0 8px var(${theme.radioHoverColour});
      }

      &:checked {
        background-color: var(${theme.radioBackgroundChecked});
        border-color: var(${theme.radioBorderColourChecked});
      }

      &:checked::before {
        color: var(--background-subtle);
      }

      &:focus {
        outline: solid var(${theme.radioFocusColour});
        outline-offset: 1px;
      }

      color: var(--text-inverse-strong);
    }

    ${screen.md} {
      margin-right: var(--spacing-small);
      padding: 0;
    }
  }

  &:focus {
    box-shadow: 0 0 3px var(--decorative-dark-ultraviolet);
  }

  ${screen.md} {
    padding: var(--spacing-xx-small) var(--spacing-300);
    ${bodyLarge};
  }

  &.invalid {
    border: var(--decorative-red) 2px solid;
  }

  &:disabled {
    opacity: 0.24;
  }
`;

export const freshInputStyles = css`
  ${sharedInputStyles}

  ${bodyLarge}

  color: var(--colour-inverse-subtle-dark-text);

  border-radius: 8px;
  border: 1px solid var(--border-cool-base);

  padding: var(--spacing-xx-small) var(--spacing-x-small);

  height: var(--spacing-600);

  &:focus {
    border-color: var(--decorative-ultraviolet);
  }

  ${screen.md} {
    height: var(--spacing-500);
  }

  @media (pointer: coarse) {
    padding: var(--spacing-x-small);
  }
`;

export const freshTextFieldInputStyles = ({ theme }) => css`
  ${sharedInputStyles}

  ${bodyLarge}
  color: var(${theme.inputColour});

  height: var(--spacing-600);
  padding: var(--spacing-x-small);
  border-radius: 8px;
  border: 1px solid var(${theme.borderColour});
  background-color: var(${theme.inputBackground});

  &::placeholder {
    color: var(${theme.textColour});
  }

  &:focus {
    border: 2px solid var(${theme.actionColour});
  }

  ${screen.md} {
    height: var(--spacing-500);
  }

  @media (pointer: fine) {
    height: var(--spacing-500);
    padding: var(--spacing-xx-small) var(--spacing-x-small);
  }
`;

export const radioText = ({ theme }) => css`
  color: var(${theme.radioText});
`;
