import { useController } from 'react-hook-form';

import { Label } from '@/components/forms/Label';

import type { InputHTMLAttributes } from 'react';
import type { Control, RegisterOptions } from 'react-hook-form';

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  control: Control;
  controlRules?: RegisterOptions;
}

export const FileInput = ({
  id,
  name,
  label,
  required,
  control,
  controlRules,
  ...props
}: FileInputProps) => {
  const { field } = useController({
    control,
    name,
    rules: controlRules,
  });

  return (
    <>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <div className="input">
        <input
          id={id}
          name={field.name}
          ref={field.ref}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          type="file"
          required={required}
          {...props}
        />
      </div>
    </>
  );
};
