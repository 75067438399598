import styled from '@emotion/styled';

import { colourTokens } from '@/styles/colours';

export const ErrorAlert = styled.div`
  width: 100%;
  height: auto;
  padding: var(--spacing-300);
  background-color: var(${colourTokens.surface.dangerSubtle.token});
  color: var(${colourTokens.text.actionDark.token});
  margin-bottom: var(--spacing-300);
  border-radius: var(--radius-s);
`;
