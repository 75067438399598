export type ConsentData = {
  legitimateInterestSubscriptionTypes: number[];
  communicationConsentCheckboxes: [
    {
      communicationTypeId: number;
      label: string;
      required: boolean;
    },
  ];
  legitimateInterestLegalBasis: string;
  communicationConsentText: string;
  processingConsentType: 'REQUIRED_CHECKBOX' | 'IMPLICIT';
  processingConsentText: string;
  processingConsentCheckboxLabel: string;
  privacyPolicyText: string;
  isLegitimateInterest: boolean;
};

export interface MetaDataItem {
  name: string;
  value: string;
}

export type MetaData = MetaDataItem[];

export const getLegalConsentFromMetadata = (
  metaData: MetaData,
): ConsentData => {
  const json = metaData?.find((item) => item.name === 'legalConsentOptions');

  return JSON.parse(json?.value ?? null);
};
